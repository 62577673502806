import { gql } from "graphql-request";

export const blockMenuLevelFragment = gql`
	fragment grpMenuSection on GrpMenuSection {
		__typename
		identifier
		label
		sys {
			id
		}
		contentCollection(limit: 20) {
			items {
				__typename
				...link
				...translationKey
				... on GrpMenuSection {
					identifier
					label
					sys {
						id
					}
					isHighlighted
					contentCollection(limit: 20) {
						items {
							...link
							...translationKey
						}
					}
				}
			}
		}
	}
`;
